import * as Yup from "yup"
import validators from "utils/validators"

export const values = {
  user_name: "",
  document_number: "",
  user_email: "",
  phone_number: "",
  agent_id: "",
  group_id: "",
  profile_id: "",
  postal_code: "",
}

export const validation = Yup.object().shape({
  user_name: Yup.string().required("Campo obrigatório"),
  document_number: Yup.string().test(
    "validDocument",
    "Documento inválido",
    (value) => validators.cpf(value)
  ),
  user_email: Yup.string()
    .required("Campo obrigatório")
    .test("validDocument", "Digite um email válido", (value) =>
      validators.email(value)
    ),
  phone_number: Yup.string().test(
    "validPhoneNumber",
    "Telefone inválido",
    (value) => validators.phone(value)
  ),
  agent_id: Yup.string(),
  group_id: Yup.string().required("Campo obrigatório"),
  profile_id: Yup.string().required("Campo obrigatório"),
  postal_code: Yup.string()
    .notRequired()
    .test(
      "validPostalCode",
      "CEP inválido",
      (value) => !value || validators.cep(value)
    ),
})
