import { useCallback, useEffect, useState } from "react"
import { navigate } from "gatsby"
import auth from "utils/auth"
import helpers from "utils/helpers"
import message from "utils/message"
import services from "services"

import { values as formValues } from "./form"

const useFormAddUser = () => {
  const [values] = useState(formValues)
  const [profiles, setProfiles] = useState([])
  const [companies, setCompanies] = useState([])

  const user = auth.getUser()

  const fetchFormData = useCallback(async () => {
    const [profiles, companies] = await Promise.all([
      services.user.profiles(),
      services.user.companies({ user_id: user?.user_id, quantity: "5000" }),
    ])

    setProfiles(profiles)
    setCompanies(companies.data)
  }, [user.user_id])

  const handleAdd = async ({ error }) => {
    if (!error) {
      message({ notification: "Usuário adicionado!" })
      await helpers.wait(2000)
      return navigate(ROUTES.users.path)
    }
  }

  const onSubmit = (formData) => {
    services.user.add(formData).then(handleAdd)
  }

  useEffect(() => {
    fetchFormData()
  }, [fetchFormData])

  return {
    values,
    profiles,
    companies,
    onSubmit,
  }
}

export default useFormAddUser
