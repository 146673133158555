import Can from "components/Can"
import PageHeader from "components/PageHeader"

import FormAddUser from "./components/FormAddUser"

const AddUser = () => {
  return (
    <Can I="management" the="users">
      <PageHeader title="Adicionar usuário" variant="small" />
      <section className="add-user">
        <FormAddUser />
      </section>
    </Can>
  )
}

export default AddUser
