import Form from "components/Forms/Form"
import Button from "components/Button"
import Card from "components/Card"
import Input from "components/Forms/Input"
import Select from "components/Forms/Select"
import maskers from "utils/maskers"
import useFormAddUser from "./hooks"

import { validation } from "./form"

import "./FormAddUser.styl"

const FormAddUser = () => {
  const { values, profiles, companies, onSubmit } = useFormAddUser()

  return (
    <Form
      className="form-add-user"
      onSubmit={onSubmit}
      defaultValues={values}
      schema={validation}
      manually={true}
    >
      {({ form, state: { isDisabled } }) => (
        <>
          <Card title="Informações de usuário" radius="medium">
            <Input
              className="form-add-user__input"
              label="Nome"
              name="user_name"
              placeholder="Nome"
              data-test="name"
              form={form}
            />
            <Input
              className="form-add-user__input"
              label="CPF"
              name="document_number"
              data-test="document"
              placeholder="CPF"
              mask={maskers.cpf}
              form={form}
            />
            <Input
              className="form-add-user__input"
              label="Email"
              type="email"
              name="user_email"
              data-test="email"
              placeholder="Email"
              form={form}
            />
            <Input
              className="form-add-user__input"
              label="Telefone celular"
              name="phone_number"
              data-test="phone"
              placeholder="Telefone"
              mask={maskers.phone}
              form={form}
            />
            <Input
              className="form-add-user__input"
              label="ID do agente"
              name="agent_id"
              data-test="agentId"
              id="agent_id"
              placeholder="Opcional"
              form={form}
            />
            <Input
              className="form-add-user__input"
              label="CEP"
              data-test="cep"
              name="postal_code"
              id="postal_code"
              placeholder="Opcional"
              mask={maskers.cep}
              form={form}
            />
          </Card>
          <Card
            className="wrapper-inputs"
            title="Empresa e perfil"
            radius="medium"
          >
            <Select
              className="form-add-user__input"
              label="Empresa"
              name="group_id"
              data-test="groupId"
              form={form}
              placeholder="Selecione..."
            >
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Select>
            <Select
              className="form-add-user__input"
              label="Perfil do usuário"
              name="profile_id"
              data-test="profileId"
              form={form}
              placeholder="Selecione..."
            >
              {profiles.map((profile) => (
                <option key={profile.id} value={profile.id}>
                  {profile.name}
                </option>
              ))}
            </Select>
          </Card>
          <Button
            className="form-add-user__submit"
            type="submit"
            data-test="submit"
            size="big"
            disabled={isDisabled}
          >
            Salvar
          </Button>
        </>
      )}
    </Form>
  )
}

export default FormAddUser
